import { override } from './classnames'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const buttonSize = (className: string, size: ButtonSize, defaultButtonStyles: string) => {
  let newClassName = className
  let sizeClasses = 'py-5 px-8'
  switch (size) {
    case 'sm':
      sizeClasses = 'py-3 px-4'
      break
    case 'xs':
      sizeClasses = 'py-2 px-3'
      break
    case 'md':
      sizeClasses = 'py-4 px-6'
      break
    case 'lg':
      sizeClasses = 'py-5 px-8'
      break
    case 'xl':
      sizeClasses = 'py-6 px-8'
      break
  }

  newClassName = override(defaultButtonStyles, newClassName)
  return override(newClassName, sizeClasses)
}
export const buttonSizeClass = (size: ButtonSize) => {
  let sizeClasses = 'py-5 px-8'
  switch (size) {
    case 'sm':
      sizeClasses = 'py-3 px-4'
      break
    case 'md':
      sizeClasses = 'py-4 px-6'
      break
    case 'lg':
      sizeClasses = 'py-5 px-8'
      break
    case 'xl':
      sizeClasses = 'py-6 px-8'
      break
  }

  return sizeClasses
}
